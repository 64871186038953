import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React from "react";
import { getDataProviders } from '../lib/@google/firebase';
import ErrorMessage from "./ErrorMessage";
import { FirestoreDataProvider } from "../pages/BackfillFormInput";
import { DocumentData, DocumentSnapshot, QuerySnapshot } from "firebase/firestore/lite";

export default function DataProviderSelect(props: any) {
    const {dataProviderSelectToParent, meterTableSortToParent, errors} = props;
    const [selectedDataProvider, setSelectedDataProvider] = React.useState<string>('');
    const [dataProviders, setDataProviders] = React.useState<Array<FirestoreDataProvider>>([]);
    const [touched, setTouched] = React.useState<boolean>(false);

    const selectDataProvider = (event: SelectChangeEvent) => {
        setSelectedDataProvider(event.target.value as string);
        dataProviderSelectToParent(JSON.parse(event.target.value as string));
        if (meterTableSortToParent){
          meterTableSortToParent([]);
        }
    };

    const fetchDataProviders = async (): Promise<{ success: boolean, data?: Array<FirestoreDataProvider>}> => {
        try {
          let raw_rows: Array<FirestoreDataProvider> = [];

          await getDataProviders().then((data_providers: QuerySnapshot) => {
            data_providers.forEach((doc: DocumentSnapshot) => {
              const doc_data: DocumentData | undefined = doc.data();
              if (doc_data) {
                raw_rows.push({
                  id: doc_data.id,
                  active: doc_data.active,
                  name: doc_data.name,
                  queue: doc_data.queue,
                  include_site_level: doc_data.include_site_level,
                  request: doc_data.request,
                  validation: doc_data.validation
                });
              }
            });
          });
    
          return { success: true, data: raw_rows };
          
        } catch (error) {
          return { success: false };
        }
      }

      React.useEffect(() => {
        (async () => {
          let res: any = await fetchDataProviders();
          if (res.success) {
            setDataProviders(res.data);
          }
        })();
      }, []);
    
    return (
        <FormControl fullWidth>
        <InputLabel id="data-provider-select-label">Data Provider</InputLabel>
        <Select labelId="data-provider-select-label"
                id="data-provider-select"
                value={selectedDataProvider}
                label="Data Provider"
                onClick={() => setTouched(true)}
                onChange={selectDataProvider}>

            {dataProviders.map((dp: FirestoreDataProvider) => {
                return (
                    <MenuItem value={JSON.stringify(dp)} key={dp.id}>{dp.name}</MenuItem>
                );
            })}

        </Select>
        {(errors && touched) && Object.values(errors).filter((value) => {if (value) return true}).map((value, index) => {
          return  <ErrorMessage key={index} error_message={value} />
        })}
      </FormControl>
    )
}