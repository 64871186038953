import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import logo from '../logo.svg';
import LogoutIcon from '@mui/icons-material/Logout';
import Button from '@mui/material/Button';
import {
  userSignIn,
  userSignOut,
  getSignedInUser
} from '../lib/@google/firebase';
import Snackbar from '@mui/material/Snackbar';
import NavMenu from './NavMenu';
import { AuthenticationContext } from '../context/authenticationContext';
import { AuthenticationContextType, Auth } from '../@types/auth';
import { useNavigate } from 'react-router-dom';

export default function MenuAppBar({ authToParent, viewToParent }: any) {
  const { auth, setAuthStatus, setAuthRoles } = React.useContext(
    AuthenticationContext
  ) as AuthenticationContextType;
  // const [auth, setAuth] = React.useState(false);
  const [authFeedbackDisplay, setAuthFeedbackDisplay] = React.useState(false);
  const [authFeedbackMessage, setAuthFeedbackMessage] = React.useState('');
  const [authSignedInUser, setAuthSignedInUser] = React.useState('');
  const navigate = useNavigate();

  React.useEffect(() => {
    getSignedInUser().then((user: any) => {
      if (user) {
        setAuthSignedInUser(user.name);
        setAuthStatus(true);
      }
      else{  
        navigate('/');
      }
    });
  }, []);

  const signIn = () => {
    userSignIn()
      .then((user: any) => {
        setAuthSignedInUser(user.name);
        setAuthStatus(true);
        setAuthFeedbackDisplay(true);
        setAuthFeedbackMessage(`${user.name} logged in.`);
      })
      .catch((err: any) => {
        console.log('userSignIn.err', err); // TODO: Handle error.
        setAuthFeedbackDisplay(true);
        setAuthFeedbackMessage('Failed to log in.');
      });
  };

  const signOut = () => {
    userSignOut()
      .then(() => {
        setAuthStatus(false);
        setAuthFeedbackDisplay(true);
        setAuthFeedbackMessage(`Logged out.`);
        navigate('/');
      })
      .catch((err: any) => {
        console.log('userSignOut.err', err); // TODO: Handle error.
        setAuthFeedbackDisplay(true);
        setAuthFeedbackMessage('Failed to log out.');
      });
  };

  const handleAuthFeedbackClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') return;
    setAuthFeedbackDisplay(false);
  };

  const handleLogoClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    navigate('/');
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position='static'>
        <Toolbar>
          {auth.status && <NavMenu viewToParent={viewToParent} />}

          <Box
            sx={{ flexGrow: 1 }}
          >
              <img src={logo} width='65.6' height='25.6' onClick={handleLogoClick} />
          </Box>

          {!auth.status && (
            <Button onClick={signIn} color='inherit'>
              Login
            </Button>
          )}
          {auth.status && (
            <>
              <h4>{authSignedInUser}</h4>
              <IconButton size='large' onClick={signOut} color='inherit'>
                <LogoutIcon />
              </IconButton>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Snackbar
        open={authFeedbackDisplay}
        autoHideDuration={3000}
        onClose={handleAuthFeedbackClose}
        message={authFeedbackMessage}
      />
    </Box>
  );
}
