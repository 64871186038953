import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, query, where, doc, getDoc, QuerySnapshot,
  DocumentData,
  CollectionReference,
  Query,
} from 'firebase/firestore/lite';
import {
  getAuth,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
  OAuthProvider,
  getIdTokenResult
} from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';

// TODO: This core functionality should become an NPM module.
// - Firestore
// -- Read
// -- Write
// --- Individual
// --- Bulk
// -- Update
// - Authentication
// -- Login Microsoft
// -- Logout Microsoft
// -- Get User Information Microsoft

const firebaseConfig = {
  apiKey: 'AIzaSyBHWeptVR7qe4XKNPAW8h6dtahBsoRYaRg',
  authDomain: 'ep-ei-glo-prd.firebaseapp.com',
  projectId: 'ep-ei-glo-prd',
  storageBucket: 'ep-ei-glo-prd.appspot.com',
  messagingSenderId: '61931779788',
  appId: '1:61931779788:web:069d8eea419e3abc557469',
  measurementId: 'G-CMEXK5P7YK'
};

export interface User {
  name: string;
  email: string;
}

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
const auth = getAuth();

const provider = new OAuthProvider('microsoft.com');

provider.setCustomParameters({
  tenant: '24e10c78-c2a9-41ae-b09a-421d2871d6f9'
});

export const userSignIn = () => {
  return new Promise((resolve, reject) => {
    signInWithPopup(auth, provider)
      .then((result) => {
        resolve(
          {
            name: result.user?.displayName,
            email: result.user?.email
          } || {}
        );
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const userSignOut = () => {
  return new Promise((resolve, reject) => {
    signOut(auth)
      .then(() => {
        resolve(true);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getSignedInUser = () => {
  return new Promise((resolve) => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const user_profile: User = {
          name: user.displayName || '',
          email: user.email || ''
        };
        resolve(user_profile);
      } else {
        resolve(null);
      }
    });
  });
};

export const getMetersForDataProvider = async (data_provider_id: string): Promise<QuerySnapshot> => {
  const db_collection: CollectionReference = collection(db, "meter");
  const db_query: Query = query(
    db_collection,
    where("data_provider_id", "==", data_provider_id)
  );
  const db_rows: QuerySnapshot = await getDocs(db_query);

  return db_rows;
};

export const getDataProviders = async () => {
  const db_collection = collection(db, 'data_provider');
  const db_query = query(db_collection, where('active', '==', true));
  const db_rows = await getDocs(db_query);

  return db_rows;
};

export const getUserClaims = async () => {
  return new Promise((resolve, reject) => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdTokenResult()
          .then((idTokenResult) => {
            resolve(idTokenResult.claims);
          })
          .catch((err: any) => {
            reject(err);
          });
      }
    });
  });
};

