import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import DeleteTasksButton from './DeleteTasksButton';
import RefreshTasksButton from './RefreshTasksButton';
import TablePagination from '@mui/material/TablePagination';
import { API_ENDPOINT } from '../env';
import moment from 'moment';
import { TextField } from '@mui/material';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';

interface Data {
  attempts: number;
  name: string;
  backfill_start: Date;
  backfill_end: Date;
  write_bq: string;
  created: Date;
  eta: Date;
}


interface CloudTask {
  createTime: Object,
  dispatchCount: number,
  dispatchDeadline: Object,
  firstAttempt: Object,
  httpRequest?: Object,
  lastAttempt: Object,
  messageType: string,
  name: string,
  responseCount: number,
  scheduleTime: Object,
  view: string
}

interface HeadCell {
  disablePadding: boolean;
  id: any;
  label: string;
  numeric: boolean;
}



interface EnhancedTableHeadProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  rowCount: number;
  order_by: string;
  order: Order;
  entity_name_path: string;
}

function EnhancedTableHead(props: EnhancedTableHeadProps) {
  const { onSelectAllClick, numSelected, rowCount, order_by, order, onRequestSort, entity_name_path } = props;

  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  const head_cells: readonly HeadCell[] = [
    {
      id: 'dispatchCount',
      numeric: true,
      disablePadding: false,
      label: 'Attempts',
    },
    {
      id: entity_name_path,
      numeric: false,
      disablePadding: false,
      label: 'Name'
    },
    {
      id: 'httpRequest.body.start',
      numeric: false,
      disablePadding: false,
      label: "Backfill Start"
    },
    {
      id: 'httpRequest.body.end',
      numeric: false,
      disablePadding: false,
      label: 'Backfill End'
    },
    {
      id: 'httpRequest.body.write_to_bigquery',
      numeric: false,
      disablePadding: false,
      label: 'Write to BQ '
    },
    {
      id: 'createTime',
      numeric: false,
      disablePadding: false,
      label: 'Created'
    },
    {
      id: 'scheduleTime',
      numeric: false,
      disablePadding: false,
      label: 'ETA'
    }
  ];

  return (
    <TableHead>
      <TableRow>
      <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all tasks',
            }}
          />
        </TableCell>
        {head_cells.map((head_cell) => (
          <TableCell
            key={head_cell.id}
            align={head_cell.numeric ? 'right' : 'left'}
            padding={head_cell.disablePadding ? 'none' : 'normal'}
          >
            <TableSortLabel
              active={order_by === head_cell.id}
              direction={order_by === head_cell.id ? order : 'asc'}
              onClick={createSortHandler(head_cell.id)}
            >
              {head_cell.label}
              {order_by === head_cell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  selectedTasks: Array<CloudTask>;
  setShouldFetchTasksToParent: any;
  setFilterToParent: any;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected, selectedTasks, setShouldFetchTasksToParent, setFilterToParent } = props;

  const handleFilterChange = (val: any) => {
    setFilterToParent(val);
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 }
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Tasks
        </Typography>
      )}
      <TextField id="filled-basic" label="Filter" variant="standard" sx={{
        width: 1200,
        maxWidth: '100%',
      }} onChange={e => handleFilterChange(e.target.value)} />
      <DeleteTasksButton selected_tasks={selectedTasks} setShouldFetchTasksToParent={setShouldFetchTasksToParent}/>
      <RefreshTasksButton setShouldFetchTasksToParent={setShouldFetchTasksToParent}/>

    </Toolbar>
  );
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  const key_path: any = typeof orderBy === 'string' ? orderBy.split('.') : [orderBy];
  const a_value = key_path.reduce((obj: any, key: any) => (obj && obj[key] !== 'undefined' ? obj[key] : undefined), a);
  const b_value = key_path.reduce((obj: any, key: any) => (obj && obj[key] !== 'undefined' ? obj[key] : undefined), b);
  if (b_value < a_value) {
    return -1;
  }
  if (b_value > a_value) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function TaskTable ( { data_provider }: any) {
  const [shouldFetchTasks, setShouldFetchTasks] = React.useState(false);
  const [taskQueueData, setTaskQueueData] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [filter_value, setFilterValue] = React.useState('');
  const [order_by, setOrderBy] = React.useState('httpRequest.body.result.history_name');
  const [order, setOrder] = React.useState<Order>('asc');

  const setShouldFetchTasksToParent = (data: any) => {
    setShouldFetchTasks(data);
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected: any = taskQueueData.filter((task_row: any) => {
      if (!filter_value) return true
        if (String(getEntityName(data_provider.name, task_row)).toLowerCase().includes(filter_value.toLowerCase())) {
          return true;
        } 
      }).map((n: any) => n);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, task_row: any) => {

    const selectedIndex = selected.findIndex((object: CloudTask )=> {
      return object.name === task_row.name;
    });

    let newSelected: any = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, task_row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const fetchTaskData = async () => {
    try{
      if (data_provider){
        setIsLoading(true);
        const req_body = {
          project: 'ep-ei-glo-prd',
          location: 'europe-west1',
          queue: data_provider.queue
        }

        const response: any = await fetch(`${API_ENDPOINT}/get_queue_tasks`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(req_body)
          });
        const task_data = await response.json();
        setShouldFetchTasks(false);
        setIsLoading(false);
        return {success: true, data: task_data.data};
        }
        return {success: false}
      }
    catch (error) {
      setIsLoading(false);
      return {success: false};
    }
  }

  const taskIsSelected = (task_row: CloudTask) => selected.findIndex((object: CloudTask )=> {
    return object.name === task_row.name;
  }) !== -1;

  const formatDate = (date_raw: any, type: string = 'normal') => {
    let date;
    switch (type) {
      case 'unix':
        date = moment.unix(date_raw);
        break;
      default:
        date = moment(date_raw);
        break;
    }
    return moment(date).format("MMM DD, YYYY, h:mm:ss A");
  }

  const getEntityName = (data_provider_name: string, entity_result: any) => {
    const name_key_path = getEntityPath(data_provider_name).split('.');
    return name_key_path.reduce((obj: any, key: any) => (obj && obj[key] !== 'undefined' ? obj[key] : undefined), entity_result);

  }

  const getEntityPath = (data_provider_name: string) => {
    switch (data_provider_name) {
      case 'Niagara Refrigeration':
        return 'httpRequest.body.result.history_name';
      case 'Niagara Netcare':
        return 'httpRequest.body.result.history_name';
      case 'Livewire':
        return 'httpRequest.body.result.pom_name';
      case 'MOL':
        return 'httpRequest.body.result.site_name';
      case 'PVSpot':
        return 'httpRequest.body.result.pv_configuration.name'
    
      default: return ''
    }
  }

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = order_by === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  React.useEffect(() => {
    setShouldFetchTasks(true);
  }, [data_provider])

  React.useEffect(() => {
    if (data_provider && shouldFetchTasks){
      (async () => {
      setTaskQueueData([]);
      let res: any = await fetchTaskData();
      if (res.success) {
        setTaskQueueData(res.data);
      }
    })();
    }
    setSelected([]);
  }, [shouldFetchTasks]);

  return (
  <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
      <EnhancedTableToolbar numSelected={selected.length} selectedTasks={selected} setShouldFetchTasksToParent={setShouldFetchTasksToParent}
      setFilterToParent={setFilterValue}/>
      { (!isLoading && taskQueueData.length > 0) && (
      <div>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
        >
          
          <EnhancedTableHead
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={taskQueueData.length}
            onRequestSort={handleRequestSort}
            order={order}
            order_by={order_by}
            entity_name_path={getEntityPath(data_provider.name)}
          />
          <TableBody>
            {
              stableSort(taskQueueData, getComparator(order, order_by)).filter((task_row: any) => {
                if (!filter_value) return true
                if (String(getEntityName(data_provider.name, task_row)).toLowerCase().includes(filter_value.toLowerCase())) {
                  return true;
                } 
              }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((task_row: any) => {
                const task_name_split = task_row.name.split("/");
                const task_id = task_name_split[task_name_split.length - 1];
                const isSelected = taskIsSelected(task_row);

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    onClick={(event: React.MouseEvent<unknown, MouseEvent>) => handleClick(event, task_row)}
                    tabIndex={-1}
                    key={task_id}
                    selected={isSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isSelected}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      padding="normal"
                      align="right"
                    >
                      {task_row.dispatchCount}
                    </TableCell>
                    <TableCell>
                      {getEntityName(data_provider.name, task_row)}
                    </TableCell>
                    <TableCell>
                      {formatDate(task_row.httpRequest.body.start)}
                    </TableCell>
                    <TableCell>
                      {formatDate(task_row.httpRequest.body.end)}
                    </TableCell>
                    <TableCell>
                      {task_row.httpRequest.body.write_to_bigquery ? 'True' : 'False'}
                    </TableCell>
                    <TableCell>
                      {formatDate(task_row.createTime.seconds, 'unix')}
                    </TableCell>
                    <TableCell>
                      {formatDate(task_row.scheduleTime.seconds, 'unix')}
                    </TableCell>
                  </TableRow>
                );
              })}

          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100, 250]}
          component="div"
          count={taskQueueData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      )}
      {(!isLoading && taskQueueData.length < 1) && (
       <Typography variant="subtitle1" component="h2" sx={{padding: 2}}>
       No Tasks in Queue for {data_provider.name}
      </Typography> 
      )}
    </Paper>
  </Box>
  )
}