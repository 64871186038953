import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function TaskQueueMessage() {

  return (
    <Box sx={{ flexGrow: 1}}>
       <Typography variant="h5" component="h2" sx={{marginBottom: 1}}>
          Task Queue
        </Typography>
        <Typography variant="subtitle1" component="h2">
          Start by selecting a Data Provider to see a list of outstanding Backfill request tasks.
        </Typography>
    </Box>
  );
  
}