import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { getSharepointIntegrationFileType } from '../../lib/@ep/sharepoint';

interface PropTypes {
  file_type: string;
  setFileType: (file_type: string) => void;
}

const SharepointFileTypeSelect = (props: PropTypes) => {
  const [fileTypes, setFileTypes] = React.useState<Array<string>>([]);

  const selectDataProvider = (event: SelectChangeEvent) => {
    props.setFileType(event.target.value);
  };

  React.useEffect(() => {
    (async () => {
      const file_types: Array<string> = await getSharepointIntegrationFileType();
      setFileTypes(file_types);
    })();
  }, []);

  return (
    <FormControl fullWidth>
      <InputLabel id="file-type-select-label">File Type</InputLabel>
      <Select
        labelId="file-type-select-label"
        id="file-type-select"
        value={props.file_type}
        label="File Type"
        onChange={selectDataProvider}>
        {fileTypes.map((file_type: string, index: number) => {
          return (
            <MenuItem value={file_type} key={index}>
              {file_type}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default SharepointFileTypeSelect;
