import * as React from 'react';
import { AuthenticationContextType, Auth } from '../@types/auth';

export const AuthenticationContext = React.createContext<AuthenticationContextType | null>(null);

const AuthenticationProvider = ({ children }: any ) => {
    const [auth, setAuth] = React.useState<Auth>(
        {
            status: false,
            roles: ["backfiller"]
        }
    )

    const setAuthStatus = (newStatus: boolean) => {
        setAuth({
            ...auth,
            status: newStatus
        })
    }

    const setAuthRoles = (newRoles: Array<string>) => {
        setAuth({
            ...auth,
            roles: newRoles
        })
    }

    return (
        <AuthenticationContext.Provider value={{ auth, setAuthStatus, setAuthRoles }}>
            {children}
        </AuthenticationContext.Provider>
    );
};

export default AuthenticationProvider;