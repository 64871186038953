import * as React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Alert, AlertColor, Button, Snackbar } from '@mui/material';
import axios from 'axios';
import { API_ENDPOINT } from '../../env';
import { PanelIntegrationsNoRows } from './PanelIntegrationsNoRows';
import { getSharepointIntegrations, IntegrationObject } from '../../lib/@ep/sharepoint';

interface PropTypes {
  file_type: string;
}

const IntegrationsGrid = (props: PropTypes) => {
  const [dataLoaded, setDataLoaded] = React.useState<boolean>(false);
  const [rows, setRows] = React.useState<Array<IntegrationObject>>([]);
  const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor>('info');

  const syncIntegration = async (integration_id: string) => {
    await axios.post(`${API_ENDPOINT}/sync_sharepoint_file`, {
      file_type: props.file_type,
      integration_id: integration_id
    });
  };

  const handleOpenSnackbar = (message: string, type: AlertColor) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(type);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  React.useEffect(() => {
    (async () => {
      setRows([]);
      setDataLoaded(false);
      const sharepoint_integrations: Array<IntegrationObject> = await getSharepointIntegrations(
        props.file_type
      );
      setRows(sharepoint_integrations);
      setDataLoaded(true);
    })();
  }, [props.file_type]);

  const columns: GridColDef[] = [
    { field: 'category', headerName: 'Category', flex: 0.5 },
    { field: 'name', headerName: 'Name', flex: 0.5 },
    { field: 'id', headerName: 'Integration ID', flex: 0.75 },
    {
      field: 'sync',
      type: 'actions',
      headerName: '',
      flex: 0.5,
      align: 'center',
      getActions: ({ row }: any) => {
        return [
          <Button
            variant="contained"
            onClick={async () => {
              handleOpenSnackbar(`Starting sync of ${row.category}: ${row.name}`, 'info');
              await syncIntegration(row.id)
                .then(() => {
                  handleOpenSnackbar(
                    `Syncing succesfully started for ${row.category}: ${row.name}`,
                    'success'
                  );
                })
                .catch((err: unknown) => {
                  const error_message = err instanceof Error ? err.message : 'Unknown Error';
                  handleOpenSnackbar(
                    `Error: Syncing failed for ${row.category}: ${row.name}, ${error_message}`,
                    'error'
                  );
                });
            }}>
            Sync
          </Button>
        ];
      }
    }
  ];

  return (
    <>
      <DataGrid
        loading={!dataLoaded}
        autoHeight={true}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25
            }
          }
        }}
        disableRowSelectionOnClick
        slots={{
          noRowsOverlay: PanelIntegrationsNoRows
        }}
      />
      <Snackbar open={openSnackbar} autoHideDuration={5000}>
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default IntegrationsGrid;
