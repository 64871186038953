import React from 'react';
import IntegrationsGrid from '../components/sharepoint-integration/IntegrationsGrid';
import SharepointFileTypeSelect from '../components/sharepoint-integration/SharepointFileTypeSelect';
import { Box, Grid, Typography } from '@mui/material';
import SharepointIntegrationMessage from '../components/sharepoint-integration/SharepointIntegrationMessage';
import { getUserClaims } from '../lib/@google/firebase';

const SharepointIntegration = () => {
  const [fileType, setFileType] = React.useState('');

  const [isAllowed, setIsAllowed] = React.useState(false);
  React.useEffect(() => {
    getUserClaims().then((claims:any)=>{setIsAllowed(claims.integrator || claims.admin);})
  }, []);

  if(!isAllowed){
    return (
      <h2>
        You do not have permissions to access the sharepoint integration interface, please request permission from an admin
      </h2>
    )
  }

  return (
    <>
      <Grid>
        {!fileType && (
          <Box sx={{ marginBottom: 2 }}>
            <SharepointIntegrationMessage />
          </Box>
        )}
        <Box sx={{ marginBottom: 5 }} width={'50%'}>
          <SharepointFileTypeSelect file_type={fileType} setFileType={setFileType} />
        </Box>
        {fileType && <IntegrationsGrid file_type={fileType} />}
      </Grid>
    </>
  );
};

export default SharepointIntegration;
