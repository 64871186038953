import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function LoginMessage() {

  return (
    <Box sx={{ flexGrow: 1 }}>
       <Typography variant="h5" component="h2">
          Welcome
        </Typography>
        <Typography variant="subtitle1" component="h2">
          Please login to use Glo.
        </Typography>
    </Box>
  );
}