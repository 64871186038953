import { ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { createTheme } from '@mui/material/styles';
import BackfillFormInput from './pages/BackfillFormInput';
import HomePage from './pages/HomePage'
import NoMatch from './pages/NoMatch';
import MenuAppBar from './components/MenuAppBar';
import TaskQueue from './pages/TaskQueue';
import { Routes, Route } from 'react-router-dom';
import AdminPanel from './pages/AdminPanel';
import SharepointIntegration from './pages/SharepointIntegration';

// Theme
const theme = createTheme({
  palette: {
    primary: {
      main: "#053D5D",
    },
    secondary: {
      main: '#f44336',
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h2',
          h2: 'h2',
          h3: 'h2',
          h4: 'h2',
          h5: 'h2',
          h6: 'h2',
          subtitle1: 'h2',
          subtitle2: 'h2',
          body1: 'span',
          body2: 'span'
        },
      },
    },
  }
});

export default function App() {

  return (
    <ThemeProvider theme={theme}>
        <Box sx={{ flexGrow: 1 }}>
          <MenuAppBar/>
        </Box>
        <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
          <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/Backfill' element={<BackfillFormInput />} />
            <Route path='/TaskQueue' element={<TaskQueue />} />
            <Route path='/AdminPanel' element={<AdminPanel />} />
            <Route path='/SharepointIntegration' element={<SharepointIntegration/>} />
            <Route path='*' element={<NoMatch/>}/>
          </Routes>
        </Box>
    </ThemeProvider>
  );
}
