import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { API_ENDPOINT } from '../env';

export default function DeleteTasksButton({ selected_tasks, setShouldFetchTasksToParent }: any) {

  const deleteTasks = (selected_tasks: any) => {
    try {
      if (selected_tasks.length > 0){
      for (let task of selected_tasks){
        const req_body = {
            name: task.name
        };

        (async () => {
          await fetch(`${API_ENDPOINT}/delete_task`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(req_body)
          });
        })()
      }
      setShouldFetchTasksToParent(true);
    }
  }
  catch (error){
    console.log('Error deleting tasks: ', error);
  }
  }

  return (
    <div>
      <IconButton size="large"
                  sx={{ color: "#053D5D" }}
                  onClick={() => deleteTasks(selected_tasks)}>
        <DeleteIcon/>
      </IconButton>
    </div>
  );
};