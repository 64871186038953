import * as React from "react";
import { AuthenticationContext } from "../context/authenticationContext";
import { AuthenticationContextType } from "../@types/auth";
import LoginMessage from "../components/LoginMessage";

const HomePage = () => {
  const { auth } = React.useContext(
    AuthenticationContext
  ) as AuthenticationContextType;

  return (
    <>
      {auth.status? <h2>Welcome to GLO</h2> : <LoginMessage />}
    </>
  );
};

export default HomePage;
