import React from 'react';
import {Typography, Box, Divider} from "@mui/material";

export default function ErrorMessage( { error_message }: any) {
    return(
    <Box>
        <Typography variant="caption" component="h6" color='#D00000' padding={0.5}>
            {error_message}
        </Typography>
    </Box>
    )
}