import { db } from "../@google/firebase"
import { getDocs, collection } from "firebase/firestore/lite"

export interface IntegrationObject {
  id: string;
  category: string;
  name: string;
}

export const getSharepointIntegrationFileType = async () => {
  const query_snapshot = await getDocs(collection(db, "sharepoint_configs"))
  const file_types: Array<string> = []
  query_snapshot.forEach((doc)=>{
    file_types.push(doc.id);
  })
  return file_types
}

export const getSharepointIntegrations = async (file_type: string) => {
  if(file_type){
    //TODO: To be replaced by @ep/firebase NPM Module
    const query_snapshot = await getDocs(collection(db, "sharepoint_configs", file_type, "integrations"))
    const mapped_rows: Array<IntegrationObject> = []
    query_snapshot.forEach((doc)=>{
      const document_data = doc.data()
      mapped_rows.push({
        id: doc.id,
        category: document_data.category.charAt(0).toUpperCase() + document_data.category.substring(1).toLowerCase(),
        name: document_data.name.charAt(0).toUpperCase() + document_data.name.substring(1).toLowerCase()
      })
    })
    return mapped_rows;
  } else {
    return []
  }
};