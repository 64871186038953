import { Box, Button, CircularProgress, Divider, Typography } from "@mui/material";
import React from "react";

export default function ButtonSpinner({buttonSpinnerToParent, label, input_invalid}: any) {

    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const timer = React.useRef<number>();

    React.useEffect(() => {
        return () => {
        clearTimeout(timer.current);
        };
    }, []);

    const handleButtonClick = () => {
        if (!loading) {
            //console.log("Backfill button clicked!");
        setSuccess(false);
        setLoading(true);
        timer.current = window.setTimeout(() => {
            setSuccess(true);
            setLoading(false);
        }, 2000);
        }
        buttonSpinnerToParent();
    };

  return (
    <div>
        <Box sx={{ m: 1, position: 'relative' }}>
            <Button
            variant="contained"
            disabled={ loading || input_invalid}
            onClick={handleButtonClick}
            fullWidth
            >
            {label}
            </Button>
            {loading && (
            <CircularProgress
                size={24}
                sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
                }}
            />
            )}
        </Box>
    </div>
  )
}