import * as React from 'react';
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { getMetersForDataProvider } from '../lib/@google/firebase';
import moment from 'moment';
import { Switch } from '@mui/material';
import { DocumentData, DocumentSnapshot, QuerySnapshot } from 'firebase/firestore/lite';
import { FirestoreDataProvider } from '../pages/BackfillFormInput';

interface PropTypes {
  data_provider: FirestoreDataProvider;
  meterTableSortToParent(data: React.SetStateAction<any>): void;
}

interface Data {
  id: number;
  active: boolean;
  identifier: string;
  name: string;
  site_name: string;
  channels: number;
  request: any;
  last_sync: Date;
  forecasting_enabled?: boolean;
}

const MeterDataGridSort = (props: PropTypes) => {
  const [rows, setMeterData] = React.useState<Array<Data>>([]);
  const [dataLoaded, setDataLoaded] = React.useState(false);

  const fetchMeterData = async (): Promise<{ success: boolean, data?: Array<Data>}> => {
    try {
      let raw_rows: Array<Data> = [];
      let total_channels: number = 0;
      await getMetersForDataProvider(props.data_provider.id).then((meters: QuerySnapshot) => {
        meters.docs.forEach((doc: DocumentSnapshot, index: number) => {
          const doc_data: DocumentData | undefined = doc.data();
          if (doc_data) {
            doc_data.last_sync =
            moment(doc_data?.last_sync?.value).format('YYYY-MM-DD hh:mm:ss') || '';
            total_channels += doc_data.request.sync_data.length;
            // TODO: Marked form row as disabled for active = false fields.
            // TODO: Require special permsission to deactivate meter via user interface. Triggered on checkbox change.
            // TODO: Firestore: Match meter id on firestore with meter id on bigquery.
            raw_rows.push({
              id: index,
              active: doc_data.active,
              identifier: doc_data.id,
              name: doc_data.name,
              site_name: props.data_provider.include_site_level ? doc_data.request.sync_data[0].body.result.station_name : '',
              channels: doc_data.request.sync_data.length,
              request: doc_data.request.sync_data,
              last_sync: doc_data.last_sync,
              forecasting_enabled: doc_data.forecasting_enabled
          });
          }
        });
      });
      return { success: true, data: raw_rows };
    } catch (error) {
      return { success: false };
    }
  };

  const handleSelected = (selected_indexes: Array<any>) => {
    let selected_meters: Array<Data> = [];
    selected_indexes.map((num) => {
      selected_meters.push(rows[num]);
    });
    props.meterTableSortToParent(selected_meters);
  };

  React.useEffect(() => {
    (async () => {
      setMeterData([]);
      setDataLoaded(false);
      let res: any = await fetchMeterData();
      if (res.success) {
        setMeterData(res.data);
        setDataLoaded(true);
      }
    })();
  }, [props.data_provider]);

  const columns: GridColDef[] = [
    {
      field: 'active',
      headerName: 'Active',
      sortable: false,
      flex: 0.25,
      renderCell: (gridData) => {
        return <Switch disabled checked={gridData.row.active} />;
      }
    },
    ...props.data_provider.include_site_level ? [{ field: 'site_name', headerName: 'Site', flex: 0.3 }] : [],
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'channels', headerName: 'Channels', flex: 0.25 },
    { field: 'last_sync', headerName: 'Last Sync', sortable: false, flex: 0.5 }
  ];

  return (
    <DataGrid
      loading={!dataLoaded}
      autoHeight={true}
      rows={rows}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 100
          }
        }
      }}
      checkboxSelection
      disableRowSelectionOnClick
      onRowSelectionModelChange={(selected_rows) => handleSelected(selected_rows)}
    />
  );
};

export default MeterDataGridSort;
