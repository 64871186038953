import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CloudIcon from '@mui/icons-material/Cloud';
import SyncIcon from '@mui/icons-material/Sync';
import { Link } from "react-router-dom"

export default function NavMenu( { viewToParent }: any) {
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open: boolean) => {
    setDrawerOpen(open);
  }

  const list = () => {
    return (
    <Box
      sx={{
        width: 250
      }}
    >
     <List>
        <ListItem key='backfill' disablePadding>
        <ListItemButton onClick={()=>toggleDrawer(false)} component={Link} to="/Backfill">
            <ListItemIcon>
                <SyncIcon />
            </ListItemIcon>
            <ListItemText primary='Backfill' />
        </ListItemButton>
        </ListItem>
        <ListItem key='taskqueue' disablePadding>
        <ListItemButton onClick={()=>toggleDrawer(false)} component={Link} to="/TaskQueue">
            <ListItemIcon>
                <FormatListBulletedIcon />
            </ListItemIcon>
            <ListItemText primary='Task Queue' />
        </ListItemButton>
        </ListItem>
        <ListItem key='sharepointintegration' disablePadding>
        <ListItemButton onClick={()=>toggleDrawer(false)} component={Link} to="/SharepointIntegration">
            <ListItemIcon>
                <CloudIcon />
            </ListItemIcon>
            <ListItemText primary='Sharepoint Integration' />
        </ListItemButton>
        </ListItem>
      </List>
    </Box>
    )
  };

  return (
    <div>
        <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => toggleDrawer(true)}
        >
            <MenuIcon />
        </IconButton>
        <Drawer
        anchor='left'
        open={drawerOpen}
        onClose={() => toggleDrawer(false)}
        >
            {list()}
        </Drawer>
    </div>
  );
}