import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';

export default function RefreshTasksButton({ setShouldFetchTasksToParent }: any) {

  const refreshTasks = () => {
      setShouldFetchTasksToParent(true);
  }

  return (
    <div>
      <IconButton size="large"
                  sx={{ color: "#053D5D" }}
                  onClick={() => refreshTasks()}>
        <RefreshIcon/>
      </IconButton>
    </div>
  );
};