import { Box, Grid } from '@mui/material';
import DataProviderSelect from '../components/DataProviderSelect';
import DateRangeVerticalInput from '../components/DateRangeVerticalInput';
import ButtonSpinner from '../components/ButtonSpinner';
import React from 'react';
import MeterDataGridSort from '../components/MeterDataGridSort';
import moment from 'moment';
import MeterBackfillMessage from '../components/MeterBackfillMessage';
import { API_ENDPOINT } from '../env';
import { AuthenticationContext } from '../context/authenticationContext';
import { AuthenticationContextType } from '../@types/auth';
import { getUserClaims } from '../lib/@google/firebase';

export interface FirestoreDataProvider {
  active: boolean;
  id: string;
  name: string;
  queue: string;
  include_site_level: boolean;
  request: {
    sync_data: {
      endpoint: string;
    };
  };
  validation: {
    max_request_days: number;
  };
}

interface FirestoreMeter {
  active?: boolean;
  id: string;
  name: string;
  data_provider_id: string;
  request: {
    sync_data: [
      {
        headers: any;
        body: any;
      }
    ];
  };
}

export default function BackfillFormInput() {
  const [dataProviderSelectData, dataProviderSelectDSetData] = React.useState<FirestoreDataProvider | null>(null);
  const [dateRangeVerticalInputData, dateRangeVerticalInputSetData] = React.useState({
    start: moment().utc().subtract(2, 'days').startOf('day'),
    end: moment().utc().subtract(1, 'days').endOf('day')
  });
  const [meterTableSortData, meterTableSortSetData] = React.useState<Array<any>>([]);
  const { auth } = React.useContext(AuthenticationContext) as AuthenticationContextType;

  const [isAllowed, setIsAllowed] = React.useState(false);
  React.useEffect(() => {
    getUserClaims().then((claims: any) => {
      setIsAllowed(claims.backfill || claims.admin);
    });
  }, []);
  const input_validity = {
    data_provider_select: {
      null_error: dataProviderSelectData ? '' : 'Select Data Provider'
    },
    date_range_vertical: {
      null_error:
        dateRangeVerticalInputData.start && dateRangeVerticalInputData.end
          ? ''
          : 'Set Start and End Date',
      max_range_error: dataProviderSelectData
        ? Math.abs(dateRangeVerticalInputData.end.diff(dateRangeVerticalInputData.start, 'days')) <=
          dataProviderSelectData.validation.max_request_days
          ? ''
          : `Date Range cannot exceed ${dataProviderSelectData.validation.max_request_days} days for ${dataProviderSelectData.name}`
        : '',
      forecasting_error: dataProviderSelectData
        ? (dateRangeVerticalInputData.start.isBefore(moment()) &&
            dateRangeVerticalInputData.end.isBefore(moment())) ||
          !meterTableSortData.some((meter) => {
            return meter.forecasting_enabled !== true;
          })
          ? ''
          : `Forecasting is not allowed for selected meters`
        : '',
      backfill_forecast_error: dataProviderSelectData
        ? (dateRangeVerticalInputData.start.isBefore(moment()) &&
            dateRangeVerticalInputData.end.isBefore(moment())) ||
          (dateRangeVerticalInputData.start.isAfter(moment()) &&
            dateRangeVerticalInputData.end.isAfter(moment()))
          ? ''
          : 'Cannot backfill and forecast in the same request. Make sure dates span from today and onwards (forecast) or today and backwards (backfill).'
        : ''
    },
    meter_table: {
      null_error: meterTableSortData.length > 0 ? '' : 'Select Meters to Backfill'
    }
  };

  const dataProviderSelectToParent = (data: React.SetStateAction<FirestoreDataProvider | null>) => {
    dataProviderSelectDSetData(data);
  };

  const dateRangeVerticalInputToParent = (data: React.SetStateAction<any>) => {
    dateRangeVerticalInputSetData(data);
  };

  const meterTableSortToParent = (data: React.SetStateAction<any>) => {
    meterTableSortSetData(data);
  };

  const buttonSpinnerToParent = () => {
    const selected_meter_count: number = meterTableSortData.length;

    const segment_size: number = 50;
    let current_segment_count: number = 0;

    for (let i: number = 0; i < selected_meter_count; i += segment_size) {
      const segment: Array<any> = meterTableSortData.slice(i, i + segment_size);
      const segment_count: number = segment.length;

      const request_body: any = {
        data: {
          start: dateRangeVerticalInputData.start,
          end: dateRangeVerticalInputData.end,
          data_provider: dataProviderSelectData,
          selected_meters: segment,
          persist: true,
          write_to_bigquery: false
        }
      };

      (async () => {
        await fetch(`${API_ENDPOINT}/queue_requests`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(request_body)
        });
      })();

      current_segment_count += segment_count;
    }
  };

  if (!isAllowed) {
    return (
      <h2>
        You do not have permissions to access the backfill interface, please request permission from
        an admin
      </h2>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        {dataProviderSelectData && (
          <MeterDataGridSort
            data_provider={dataProviderSelectData}
            meterTableSortToParent={meterTableSortToParent}
          />
        )}
        {!dataProviderSelectData && <MeterBackfillMessage />}
      </Grid>

      <Grid item xs={3}>
        <Box sx={{ marginBottom: 2 }}>
          <DataProviderSelect
            dataProviderSelectToParent={dataProviderSelectToParent}
            meterTableSortToParent={meterTableSortToParent}
            errors={input_validity.data_provider_select}
          />
        </Box>

        <Box sx={{ marginBottom: 3 }}>
          <DateRangeVerticalInput
            dateRangeVerticalInputToParent={dateRangeVerticalInputToParent}
            start_label="From"
            end_label="To"
            data_provider={dataProviderSelectData}
            errors={input_validity.date_range_vertical}
          />
        </Box>

        <Box sx={{ alignItems: 'center', marginBottom: 3 }}>
          <ButtonSpinner
            buttonSpinnerToParent={buttonSpinnerToParent}
            label="Backfill"
            input_invalid={Object.values(input_validity).some((inner_object) => {
              return Object.values(inner_object).some((value) => value !== '');
            })}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

