import React from "react";
import TaskTable from "../components/TaskTable";
import { Box, Button, Grid, Typography } from "@mui/material";
import DataProviderSelect from "../components/DataProviderSelect";
import TaskQueueMessage from "../components/TaskQueueMessage";
import { getUserClaims } from "../lib/@google/firebase";

export default function TaskQueue() {
  const [taskQueueData, taskQueueSetData] = React.useState([]);
  const [dataProviderSelectData, dataProviderSelectDSetData]: any =
    React.useState(null);

  const taskQueueDataToParent = (data: any) => {
    taskQueueSetData(data);
  };

  const dataProviderSelectToParent = (data: React.SetStateAction<any>) => {
    dataProviderSelectDSetData(data);
  };
  
  const [isAllowed, setIsAllowed] = React.useState(false);
  React.useEffect(() => {
    getUserClaims().then((claims:any)=>{setIsAllowed(claims.taskviewer || claims.admin);})
  }, []);

  if(!isAllowed){
    return (
      <h2>
        You do not have permissions to access the task view interface, please request permission from an admin
      </h2>
    )
  }

  return (
    <Grid>
      <Box sx={{ marginBottom: 5 }} width={"50%"}>
        <DataProviderSelect
          dataProviderSelectToParent={dataProviderSelectToParent}
        />
      </Box>
      {dataProviderSelectData && (
        <TaskTable
          task_queue_data={taskQueueData}
          data_provider={dataProviderSelectData}
        />
      )}
      {!dataProviderSelectData && <TaskQueueMessage />}
    </Grid>
  );
}
