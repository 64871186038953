import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { FormControl, FormLabel, InputLabel, MenuItem, Select } from '@mui/material';
import { useState, useEffect, useContext } from 'react';
import { API_ENDPOINT } from '../env';
import { getUserClaims } from '../lib/@google/firebase';
import { AuthenticationContext } from '../context/authenticationContext';
import { AuthenticationContextType } from '../@types/auth';
import { Form } from 'react-router-dom';

const AdminPanel = () => {
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [isAllowed, setIsAllowed] = useState(false);

  const { auth } = useContext(AuthenticationContext) as AuthenticationContextType;

  useEffect(() => {
    getUserClaims().then((claims: any) => {
      setIsAllowed(claims.admin);
    });
  }, []);

  const handleAdd = (event: any) => {
    event.preventDefault();

    const request_body: any = {
      userEmail: email,
      userRole: role
    };

    (async () => {
      fetch(`${API_ENDPOINT}/add_user_role`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(request_body)
      })
        .then((response) => {
          return response;
        })
        .catch((err) => {
          return err;
        });
    })();
    //Run add admin role
    setEmail('');
    setRole('');
  };

  const handleRemove = (event: any) => {
    event.preventDefault();

    const request_body: any = {
      userEmail: email,
      userRole: role
    };

    (async () => {
      fetch(`${API_ENDPOINT}/remove_user_role`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(request_body)
      })
        .then((response) => {
          return response;
        })
        .catch((err) => {
          return err;
        });
    })();
    //Run add admin role
    setEmail('');
    setRole('');
  };

  const handleRoleChange = (event: any) => {
    setRole(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  if (!isAllowed) {
    return (
      <h2>
        You do not have permissions to access the Admin Panel interface, please request permission
        from an admin
      </h2>
    );
  }

  return (
    <>
      <form>
        <FormControl>
          <TextField
            label="User Email"
            variant="outlined"
            onChange={handleEmailChange}
            value={email}></TextField>
          <br />
          <FormControl>
            <InputLabel id="role-select-label">Role</InputLabel>
            <Select
              labelId="role-select-label"
              label="Role"
              value={role}
              onChange={handleRoleChange}>
              <MenuItem value={'admin'}>admin</MenuItem>
              <MenuItem value={'backfill'}>backfill</MenuItem>
              <MenuItem value={'taskviewer'}>task viewer</MenuItem>
              <MenuItem value={'integrator'}>sharepoint integrator</MenuItem>
            </Select>
          </FormControl>
          <br />
          <Button onClick={handleAdd} variant="outlined">
            Add Role
          </Button>
          <br />
          <Button onClick={handleRemove} variant="outlined">
            Remove Role
          </Button>
        </FormControl>
      </form>
    </>
  );
};

export default AdminPanel;
