import { Stack, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment, { Moment } from "moment";
import React from "react";
import ErrorMessage from "./ErrorMessage";

interface DateRange {
    start: any;
    end: any;
}

export default function DateRangeVerticalInput({dateRangeVerticalInputToParent, start_label, end_label, errors}: any) {
    const [start_value, setValueDatePickerStart] = React.useState<Moment | null>( moment().utc().subtract(2, 'days').startOf('day') );
    const [end_value, setValueDatePickerEnd] = React.useState<Moment | null>( moment().utc().subtract(1, 'days').endOf('day') );

    const handleChangeDatePickerStart = (newValue: Moment | null) => {
        setValueDatePickerStart(newValue);
        dateRangeVerticalInputToParent({
            "start": newValue,
            "end": end_value
        });
    };
    
    const handleChangeDatePickerEnd = (newValue: Moment | null) => {
        setValueDatePickerEnd(newValue);
        dateRangeVerticalInputToParent({
            "start": start_value,
            "end": newValue
        });
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Stack spacing={3} sx={{ marginBottom: 2 }}>
            <DesktopDatePicker
                label={start_label}
                inputFormat="MM/DD/YYYY"
                value={start_value}
                onChange={handleChangeDatePickerStart}
                renderInput={(params) => <TextField {...params} />}
            />
            </Stack>
            <Stack spacing={3}>
            <DesktopDatePicker
                label={end_label}
                inputFormat="MM/DD/YYYY"
                value={end_value}
                onChange={handleChangeDatePickerEnd}
                renderInput={(params) => <TextField {...params} />}
            />
            </Stack>
            {Object.values(errors).filter((value) => {if (value) return true}).map((value) => {
                return  <ErrorMessage key={value} error_message={value} />
            })}
        </LocalizationProvider>
    )
}