import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function SharepointIntegrationMessage() {

  return (
    <Box sx={{ flexGrow: 1 }}>
       <Typography variant="h5" component="h2">
          Sharepoint Integrations
        </Typography>
        <Typography variant="subtitle1" component="h2">
          Start by selecting a file type to see a list of available integrations.
        </Typography>
    </Box>
  );
  
}